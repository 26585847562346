// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-board-members-js": () => import("./../../../src/pages/about-us/board-members.js" /* webpackChunkName: "component---src-pages-about-us-board-members-js" */),
  "component---src-pages-about-us-history-js": () => import("./../../../src/pages/about-us/history.js" /* webpackChunkName: "component---src-pages-about-us-history-js" */),
  "component---src-pages-about-us-index-js": () => import("./../../../src/pages/about-us/index.js" /* webpackChunkName: "component---src-pages-about-us-index-js" */),
  "component---src-pages-about-us-key-achievements-index-js": () => import("./../../../src/pages/about-us/key-achievements/index.js" /* webpackChunkName: "component---src-pages-about-us-key-achievements-index-js" */),
  "component---src-pages-about-us-key-achievements-timeline-index-js": () => import("./../../../src/pages/about-us/key-achievements/timeline/index.js" /* webpackChunkName: "component---src-pages-about-us-key-achievements-timeline-index-js" */),
  "component---src-pages-about-us-our-culture-index-js": () => import("./../../../src/pages/about-us/our-culture/index.js" /* webpackChunkName: "component---src-pages-about-us-our-culture-index-js" */),
  "component---src-pages-about-us-our-culture-story-of-shimmer-art-js": () => import("./../../../src/pages/about-us/our-culture/story-of-shimmer-art.js" /* webpackChunkName: "component---src-pages-about-us-our-culture-story-of-shimmer-art-js" */),
  "component---src-pages-bett-2023-js": () => import("./../../../src/pages/bett2023.js" /* webpackChunkName: "component---src-pages-bett-2023-js" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-contact-us-index-js": () => import("./../../../src/pages/contact-us/index.js" /* webpackChunkName: "component---src-pages-contact-us-index-js" */),
  "component---src-pages-download-bett-js": () => import("./../../../src/pages/downloadBett.js" /* webpackChunkName: "component---src-pages-download-bett-js" */),
  "component---src-pages-download-dudu-js": () => import("./../../../src/pages/downloadDudu.js" /* webpackChunkName: "component---src-pages-download-dudu-js" */),
  "component---src-pages-download-qoqolo-js": () => import("./../../../src/pages/downloadQoqolo.js" /* webpackChunkName: "component---src-pages-download-qoqolo-js" */),
  "component---src-pages-download-readin-js": () => import("./../../../src/pages/downloadReadin.js" /* webpackChunkName: "component---src-pages-download-readin-js" */),
  "component---src-pages-download-sl-js": () => import("./../../../src/pages/downloadSL.js" /* webpackChunkName: "component---src-pages-download-sl-js" */),
  "component---src-pages-download-sw-js": () => import("./../../../src/pages/downloadSW.js" /* webpackChunkName: "component---src-pages-download-sw-js" */),
  "component---src-pages-home-components-01-hero-d-3-force-js": () => import("./../../../src/pages/home/components/01-Hero/D3/Force.js" /* webpackChunkName: "component---src-pages-home-components-01-hero-d-3-force-js" */),
  "component---src-pages-home-components-01-hero-d-3-styled-js": () => import("./../../../src/pages/home/components/01-Hero/D3/styled.js" /* webpackChunkName: "component---src-pages-home-components-01-hero-d-3-styled-js" */),
  "component---src-pages-home-components-01-hero-index-js": () => import("./../../../src/pages/home/components/01-Hero/index.js" /* webpackChunkName: "component---src-pages-home-components-01-hero-index-js" */),
  "component---src-pages-home-components-01-hero-parallax-layers-index-js": () => import("./../../../src/pages/home/components/01-Hero/ParallaxLayers/index.js" /* webpackChunkName: "component---src-pages-home-components-01-hero-parallax-layers-index-js" */),
  "component---src-pages-home-components-02-benefits-index-js": () => import("./../../../src/pages/home/components/02-Benefits/index.js" /* webpackChunkName: "component---src-pages-home-components-02-benefits-index-js" */),
  "component---src-pages-home-components-03-our-app-index-js": () => import("./../../../src/pages/home/components/03-OurApp/index.js" /* webpackChunkName: "component---src-pages-home-components-03-our-app-index-js" */),
  "component---src-pages-home-components-04-testimonial-index-js": () => import("./../../../src/pages/home/components/04-Testimonial/index.js" /* webpackChunkName: "component---src-pages-home-components-04-testimonial-index-js" */),
  "component---src-pages-home-components-05-evolve-index-js": () => import("./../../../src/pages/home/components/05-Evolve/index.js" /* webpackChunkName: "component---src-pages-home-components-05-evolve-index-js" */),
  "component---src-pages-home-index-js": () => import("./../../../src/pages/home/index.js" /* webpackChunkName: "component---src-pages-home-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-products-commonacademy-index-js": () => import("./../../../src/pages/products/commonacademy/index.js" /* webpackChunkName: "component---src-pages-products-commonacademy-index-js" */),
  "component---src-pages-products-commonacademy-styled-js": () => import("./../../../src/pages/products/commonacademy/styled.js" /* webpackChunkName: "component---src-pages-products-commonacademy-styled-js" */),
  "component---src-pages-products-dudu-js": () => import("./../../../src/pages/products/dudu.js" /* webpackChunkName: "component---src-pages-products-dudu-js" */),
  "component---src-pages-products-dudu-together-js": () => import("./../../../src/pages/products/dudu-together.js" /* webpackChunkName: "component---src-pages-products-dudu-together-js" */),
  "component---src-pages-products-grid-content-index-js": () => import("./../../../src/pages/products/GridContent/index.js" /* webpackChunkName: "component---src-pages-products-grid-content-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-qoqolo-elderly-js": () => import("./../../../src/pages/products/qoqolo-elderly.js" /* webpackChunkName: "component---src-pages-products-qoqolo-elderly-js" */),
  "component---src-pages-products-qoqolo-js": () => import("./../../../src/pages/products/qoqolo.js" /* webpackChunkName: "component---src-pages-products-qoqolo-js" */),
  "component---src-pages-products-readin-js": () => import("./../../../src/pages/products/readin.js" /* webpackChunkName: "component---src-pages-products-readin-js" */),
  "component---src-pages-products-swiiit-js": () => import("./../../../src/pages/products/swiiit.js" /* webpackChunkName: "component---src-pages-products-swiiit-js" */),
  "component---src-pages-products-vatitude-js": () => import("./../../../src/pages/products/vatitude.js" /* webpackChunkName: "component---src-pages-products-vatitude-js" */),
  "component---src-pages-showcase-commonacademy-js": () => import("./../../../src/pages/showcase/commonacademy.js" /* webpackChunkName: "component---src-pages-showcase-commonacademy-js" */),
  "component---src-pages-showcase-components-01-tabs-index-js": () => import("./../../../src/pages/showcase/components/01-Tabs/index.js" /* webpackChunkName: "component---src-pages-showcase-components-01-tabs-index-js" */),
  "component---src-pages-showcase-components-02-tab-content-index-js": () => import("./../../../src/pages/showcase/components/02-Tab-Content/index.js" /* webpackChunkName: "component---src-pages-showcase-components-02-tab-content-index-js" */),
  "component---src-pages-showcase-eldercare-js": () => import("./../../../src/pages/showcase/eldercare.js" /* webpackChunkName: "component---src-pages-showcase-eldercare-js" */),
  "component---src-pages-showcase-index-js": () => import("./../../../src/pages/showcase/index.js" /* webpackChunkName: "component---src-pages-showcase-index-js" */),
  "component---src-pages-showcase-qoqolo-js": () => import("./../../../src/pages/showcase/qoqolo.js" /* webpackChunkName: "component---src-pages-showcase-qoqolo-js" */),
  "component---src-pages-showcase-swiiit-js": () => import("./../../../src/pages/showcase/swiiit.js" /* webpackChunkName: "component---src-pages-showcase-swiiit-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-listing-js": () => import("./../../../src/templates/listing.js" /* webpackChunkName: "component---src-templates-listing-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */)
}

